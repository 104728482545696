import { useState } from "react";
import { useDispatch } from "react-redux";
import { actions as userConversationActions } from "../../../store/userConversation/slice";
import UserConversationService from "../../../api/userConversation/userConversationService";
import { createCompanyData } from "../../../utils/companyUtils.js";

const useClickableCompanyNames = () => {
  const dispatch = useDispatch();
  const [companyIds, setCompanyIds] = useState([]);
  const [fetchedCompanies, setFetchedCompanies] = useState([]);

  const userConversationService = new UserConversationService();

  const parseCompanyNamesInLinks = (text, companyLinksData) => {
    return new Promise(function (resolve) {
      let resultText = text;
      let companyIdsFromText = [];
      if (companyLinksData && companyLinksData.length) {
        // Create a regular expression to match any of the company names
        const REGEXP_SPECIAL_CHAR =
          /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
        const regex = new RegExp(
          companyLinksData
            .map(company => company.name.replace(REGEXP_SPECIAL_CHAR, "\\$&"))
            .join("|"),
          "g",
        );

        // Replace each matched company name with a link
        resultText = text.replace(regex, match => {
          const foundCompany = companyLinksData.find(
            company => company.name === match,
          );
          if (foundCompany) {
            if (!companyIdsFromText.find(id => id === foundCompany.id)) {
              companyIdsFromText.push(foundCompany.id);
            }
            return `<a data-company="${foundCompany.name}">${match}</a>`;
          }
          return match;
        });
        setCompanyIds(companyIdsFromText);
      }
      resolve({ resultText, companyIdsFromText });
    });
  };

  const fetchCompanies = (
    companyIdsFromText,
    contextObjectId,
    conversationIndex,
  ) => {
    userConversationService.getCompanies(companyIdsFromText).then(data => {
      if (data) {
        const companiesPreviewData = data.map(company =>
          createCompanyData(
            company.name,
            company.description,
            company.total_funding_amount_dollars,
            company.founded_date,
            company.number_of_employees,
            company.investment_stage,
            company.hq_country,
            (company.degree_of_fit * 100).toFixed(),
            company.slug,
            company.id,
            company.enrichment_request,
            company.ready_for_release,
            company.website,
            company.deck,
            company.sectors,
            company.comments_cnt,
            company.subsectors,
            company.projects,
            company.product_portfolio,
          ),
        );
        setFetchedCompanies(companiesPreviewData);
        dispatch(
          userConversationActions.setCompanyLinksData({
            id: contextObjectId,
            index: conversationIndex,
            companiesPreviewData,
          }),
        );
      }
    });
  };

  return {
    companyIds,
    fetchedCompanies,
    fetchCompanies,
    parseCompanyNamesInLinks,
  };
};

export default useClickableCompanyNames;
