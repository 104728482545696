import React, { useRef } from "react";
import { useStyles } from "../style.js";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  createUserConversationRequest,
  updateUserConversationRequest,
} from "../../../store/userConversation/requests";
import {
  getConversationHistoryStatusSelector,
  userConversationSelector,
} from "../../../store/userConversation/selectors";
import { getUserSelector } from "../../../store/auth/selectors";
// Constants
import { REQUEST_PENDING } from "../../../constants/statuses";

const UserInput = ({
  contextObject,
  contextName,
  selectedFiltersCount,
  conversationContext,
  onEnterPress,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userConversationData = useSelector(userConversationSelector);
  const conversationHistoryStatus = useSelector(
    getConversationHistoryStatusSelector,
  );
  const user = useSelector(getUserSelector);
  const userQuestionTextarea = useRef(null);

  //Send user input
  const handleKeyDownPress = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      onEnterPress(event);
      event.preventDefault();
      // existing conversation
      if (
        contextObject &&
        contextObject.conversation_id &&
        selectedFiltersCount === 0
      ) {
        let payload = {
          conversation_id: contextObject.conversation_id,
          deep_dive_question: event.target.value,
          conversation_context: contextObject.conversation_context,
          scope_description: contextObject.description,
          search_id: contextObject.id,
        };
        if (user && user.llm_region) {
          payload.llm_region = user.llm_region;
        }
        dispatch(
          updateUserConversationRequest({
            data: payload,
            id: contextObject.id,
          }),
        );
        // create new conversation
      } else {
        // Workaround - if filters are active we are creating a new conversation and sending new conversation_context
        let context = contextObject.conversation_context;
        if (selectedFiltersCount > 0) {
          context = conversationContext;
        }
        let payload = {
          deep_dive_question: event.target.value,
          conversation_context: context,
          scope_description: contextObject.description,
          search_id: contextObject.id,
          has_filters: !!selectedFiltersCount,
          scope_guide_steps: {
            STEP_1: contextObject.scope_guide_step_1 || "empty",
            STEP_2: contextObject.scope_guide_step_2 || "empty",
            STEP_3: contextObject.scope_guide_step_3 || "empty",
            STEP_4: contextObject.scope_guide_step_4 || "empty",
            STEP_5: contextObject.scope_guide_step_5 || "empty",
          },
        };
        if (user && user.llm_region) {
          payload.llm_region = user.llm_region;
        }
        dispatch(
          createUserConversationRequest({
            data: payload,
            id: contextObject.id,
          }),
        );
      }
      userQuestionTextarea.current.value = "";
    }
  };

  const isDisabled = () => {
    return (
      contextObject &&
      (!userConversationData ||
        !userConversationData[contextObject.id] ||
        !userConversationData[contextObject.id].conversation) &&
      conversationHistoryStatus === REQUEST_PENDING
    );
  };

  return (
    <div className={classes.userInput}>
      <TextareaAutosize
        disabled={isDisabled()}
        className={classes.textareaField}
        minRows={5}
        maxRows={30}
        placeholder="Ask me anything about your results..."
        ref={userQuestionTextarea}
        onKeyDown={handleKeyDownPress}
      />
    </div>
  );
};

export default UserInput;
