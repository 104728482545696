import { call } from "redux-saga/effects";
import axios from "axios";
import {
  CHAT_CONVERSATION_URL,
  getConversationHistoryUrl,
  SAVE_USER_CONVERSATION_ID_URL,
} from "../../../constants/endpoints";
import { SocketService } from "../../../services/socketService";

function* getUserConversationHelper(data) {
  const response = yield call(axios, {
    method: "POST",
    url: CHAT_CONVERSATION_URL,
    data,
    withCredentials: true,
    auth: {
      username: process.env.REACT_APP_ML_USER,
      password: process.env.REACT_APP_ML_PASSWORD,
    },
  });
  return response.data;
}

function* createUserConversationHelper(data) {
  const socketService = new SocketService();
  socketService.getSocketConnection();

  // const response = yield call(axios, {
  //     method: "POST",
  //     url: CHAT_CONVERSATION_URL,
  //     data,
  //     withCredentials: true,
  //     auth: {
  //         username: process.env.REACT_APP_ML_USER,
  //         password: process.env.REACT_APP_ML_PASSWORD,
  //     },
  // });
  // return response.data;
  yield socketService.sendMessage(data);
}

function* updateUserConversationHelper(data) {
  const socketService = new SocketService();
  socketService.getSocketConnection();

  // const response = yield call(axios, {
  //     method: "POST",
  //     url: CHAT_CONVERSATION_URL,
  //     data,
  //     withCredentials: true,
  //     auth: {
  //         username: process.env.REACT_APP_ML_USER,
  //         password: process.env.REACT_APP_ML_PASSWORD,
  //     },
  // });
  // return response.data;
  yield socketService.sendMessage(data);
}

function* saveUserConversationIdHelper(data) {
  const response = yield call(axios, {
    method: "POST",
    url: SAVE_USER_CONVERSATION_ID_URL,
    data,
    withCredentials: true,
  });
  return response.data;
}

function* getPumaSummaryHelper(data) {
  const response = yield call(axios, {
    method: "POST",
    url: CHAT_CONVERSATION_URL,
    data,
    withCredentials: true,
    auth: {
      username: process.env.REACT_APP_ML_USER,
      password: process.env.REACT_APP_ML_PASSWORD,
    },
  });
  return response.data;
}

function* getConversationHistoryHelper(id) {
  const response = yield call(axios, {
    method: "GET",
    url: getConversationHistoryUrl(id),
    withCredentials: true,
  });
  return response.data;
}

export {
  getUserConversationHelper,
  createUserConversationHelper,
  updateUserConversationHelper,
  saveUserConversationIdHelper,
  getPumaSummaryHelper,
  getConversationHistoryHelper,
};
