import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { useSearches } from "../../../common/useSearches.js";

import { useStyles } from "./style.js";
import { ReactComponent as NewPumaLogo } from "../../../assets/icons/MangaPuma.svg";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";

import Modal from "./Modal/Modal.js";
import MultiSelectSort from "./Select/Select.js";
import { regionsOptions } from "./data.js";
import { getCountriesSelector } from "../../../store/countries/selectors.js";
import { actions as searchActions } from "../../../store/search/slice";
import { getSearchLocationSelector } from "../../../store/search/selectors.js";
import { useHistory, useLocation } from "react-router-dom";

import {
  fetchSectorsRequest,
  fetchSubsectorsRequest,
} from "../../../store/sectors/requests.js";
import usePrevious from "../../../common/usePrevious.js";
import { getCountriesRequest } from "../../../store/countries/requests.js";
import { useSectorsHook } from "../hooks/useSectorsHook.js";
import { getSubsectorsSelector } from "../../../store/sectors/selectors.js";
import {
  postSetSearchCountRequest,
  submitSearchRequest,
} from "../../../store/search/requests.js";
import { useScopeGuidance } from "../../../common/useScopeGuidance.js";
import Markdown from "../../../components/Markdown/Markdown.js";

const OwnPrompt = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getRecentSearches: { data: searches },
    deleteSearch,
  } = useSearches();
  const { allSectors } = useSectorsHook();
  const searchLocation = useSelector(getSearchLocationSelector);
  const subsectors = useSelector(getSubsectorsSelector);
  const textAreaRef = useRef(null);
  const lookalikeRef = useRef(null);
  const classes = useStyles();
  const [regionsChanged, setRegionsChanged] = useState(false);
  const [countriesChanged, setCountriesChanged] = useState(false);
  const { pathname } = useLocation();
  const [selectedIndustryHistory, setSelectedIndustryHistroy] = useState([]);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [activeScope, setActiveScope] = useState(1);
  const [scopeValue, setScopeValue] = useState("");
  const [lookalikeValue, setLookalikeValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getChip, setChip] = useState();
  const countryMenuItems = useSelector(getCountriesSelector);
  const [filteredRegionMenuItems, setFilteredRegionMenuItems] = useState();
  const [filteredIndustriesMenuItems, setFIlteredIndustriesMenuItems] =
    useState();
  const [selectedSubindustries, setSelectedSubindustries] = useState([]);
  const [subsectorsValue, setSubsectorsValue] = useState(subsectors);
  const [regionMenuItems, setRegionMenuItems] = useState(regionsOptions);
  const [industriesMenuItems, setIndustriesMenuItems] = useState(allSectors);
  const [regionsInputValue, setRegionsInputValue] = useState("");
  const [regionsMenuOpened, setRegionsMenuOpened] = useState(false);
  const [countriesInputValue, setCountriesInputValue] = useState("");
  const [filteredCountryMenuItems, setFilteredCountryMenuItems] = useState();
  const [countriesMenuOpened, setCountriesMenuOpened] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(undefined);
  const [errorMsg, setErrorMsgs] = useState(null);
  const [selectedIndustriesInfo, setSelectedIndustriesInfo] = useState([]);
  const [activeSubsectors, setActiveSubsectors] = useState([]);
  const [previewScopes, setPreviewScopes] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [openDeleteSearchModal, setOpenDeleteSearchModal] = useState(false);
  const [selectedScope, setSelectedScope] = useState(null);

  const {
    sendPromptTest: { data, isLoading, refetch, isFetching },
    generateScopePrompt: {
      data: generatedScopePrompt,
      isLoading: isGenerating,
      refetch: refetchGenerateScopePrompt,
      isFetching: isGeneratingFetching,
    },
  } = useScopeGuidance({
    llm_id: "gpt-4o-openai",
    query: scopeValue,
    system_message_group: "STEP_6",
  });

  const transfromToLowerWithDashes = string => {
    if (typeof string === "string") {
      return string.toLowerCase().replaceAll(" ", "-");
    }
    return string;
  };

  const [selectedRegions, setSelectedRegions] = useState(
    searchLocation
      ? typeof searchLocation === "string"
        ? JSON.parse(searchLocation).regions
        : searchLocation.regions
      : [],
  );
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedIndustriesValue, setSelectedIndustriesValue] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(
    searchLocation
      ? typeof searchLocation === "string"
        ? JSON.parse(searchLocation).countries
        : searchLocation.countries
      : [],
  );
  const [selectedCountriesValue, setSelectedCountriesValue] = useState([]);

  const prevSelectedCountries = usePrevious(selectedCountries);
  const childData = example => {
    setActiveScope(1);
    setScopeValue(example.value);
    setIsModalOpen(false);
    setChip(example);
  };

  const handleMenuSearch = type => {
    setRegionsMenuOpened(false);
    setCountriesMenuOpened(false);
    typingTimeout !== undefined &&
      clearTimeout(typingTimeout) &&
      setTypingTimeout(undefined);
    setTypingTimeout(
      setTimeout(() => {
        filterMenu(type);
        setTypingTimeout(undefined);
      }, 750),
    );
  };

  const handleSubmitSearch = e => {
    // mixpanelTrackEvent("Company search", {
    //     Page: page,
    //     Regions: selectedRegions,
    //     Countries: selectedCountries,
    //     Industries: selectedSectors.map(sector => sector.name),
    //     Subindustries: selectedSubsectors,
    //     EditMode: !!isEditMode,
    //     Type: "Regular",
    // });
    handleCaptchaChange();

    dispatch(searchActions.setEditQueryClean());
  };
  useEffect(() => {
    if (selectedIndustries.length === 0) {
      setActiveSubsectors([]);
      setSelectedSubindustries([]);
      setSelectedIndustryHistroy();
      setSubsectorsValue([]);
    }
    if (selectedRegions.length === 0) {
      setFilteredCountryMenuItems();
      setSelectedCountries([]);
      setSelectedCountriesValue([]);
    }
  }, [selectedRegions, selectedIndustries]);

  const handleClick = () => {
    handleSubmitSearch();
    // alert(handleClick)
  };

  const filterMenu = type => {
    if (type === "region") {
      regionsInputValue.length
        ? setFilteredRegionMenuItems(
            regionMenuItems.filter(item =>
              item.name
                .toLowerCase()
                .startsWith(regionsInputValue.toLowerCase()),
            ),
          )
        : setFilteredRegionMenuItems(null);
      setRegionsMenuOpened(true);
    }
    if (type === "country") {
      countriesInputValue.length
        ? setFilteredCountryMenuItems(
            countryMenuItems.filter(item =>
              item.name
                .toLowerCase()
                .startsWith(countriesInputValue.toLowerCase()),
            ),
          )
        : setFilteredCountryMenuItems(null);
      setCountriesMenuOpened(true);
    }
  };

  const handleTextAreaClick = () => {
    setActiveScope(1);
  };
  const modalStatus = modalStatus => {
    setIsModalOpen(modalStatus);
  };

  const handleActiveScope = () => {
    textAreaRef.current?.focus();
    setActiveScope(1);
  };

  const handleRegionSelect = arrayOfRegion => {
    const parsedSelected = [];
    const selected = [];
    arrayOfRegion.filter(region => {
      selected.push(region.label);
      parsedSelected.push({
        label: region.label,
        value: transfromToLowerWithDashes(region.label),
      });
    });
    setSelectedRegions(selected);
    setRegionsChanged(true);
    dispatch(searchActions.updateRegions(selected));
  };

  const handleCaptchaChange = () => {
    let data;
    if (selectedScope) {
      data = {
        description: selectedScope.description,
        searchSectors: selectedIndustriesInfo,
        location: {
          regions: selectedRegions[0],
          countries: selectedCountriesValue,
        },
        scope: selectedScope.scope,
        searchType: activeScope,
        perPage: 100,
        multi_tenant_entities: ["product_portfolio"],
      };
      console.log("scope object", data);
    } else {
      data = {
        description: scopeValue,
        searchSectors: selectedIndustriesInfo,
        location: {
          regions: selectedRegions[0],
          countries: selectedCountriesValue,
        },
        scope: "Find companies that use specific technologies",
        searchType: activeScope,
        perPage: 100,
        multi_tenant_entities: ["product_portfolio"],
      };
    }
    dispatch(postSetSearchCountRequest());
    dispatch(
      submitSearchRequest({
        description: data.description,
        searchSectors: data.searchSectors,
        location: {
          regions: data.location.regions,
          countries: data.location.countries,
        },
        scope: data.scope,
        searchType: data.searchType,
        perPage: 50,
        multi_tenant_entities: ["product_portfolio"],
      }),
    );
    setTimeout(() => {
      history.replace("/search-results-loading", {
        from: pathname,
        someOtherProp: "",
      });
    }, 200);
  };

  const handleCountrySelect = arrayOfCountries => {
    const parsedSelected = [];
    const selected = [];
    arrayOfCountries.filter(country => {
      selected.push(country.label);
      parsedSelected.push({
        label: country.label,
        value: transfromToLowerWithDashes(country.label),
      });
    });

    setSelectedCountries(selected);
    setSelectedCountriesValue(selected);
    setCountriesChanged(true);
    dispatch(searchActions.updateCountries(selected));
  };

  const handleIndustriesSelect = arrayOfIndustries => {
    let selected = [];
    let parseSelected = [];
    let objectedArray = [];
    let parseArray = [];
    arrayOfIndustries.filter(country => {
      selected.push(country.value);
      parseSelected.push(country.value);
      objectedArray.push({ name: country.label, slug: country.value });
      parseArray.push({
        name: country.label,
        slug: country.value,
        subsectors: [],
      });
    });
    setSelectedIndustriesInfo(parseArray);
    setSelectedIndustries(selected);
    setSelectedIndustriesValue(objectedArray);
    setSelectedIndustryHistroy(selectedIndustries);
    dispatch(fetchSubsectorsRequest(parseSelected[parseSelected.length - 1]));
    dispatch(searchActions.addSectors(parseSelected));
  };

  useEffect(() => {
    setSubsectorsValue(subsectors);
  }, [subsectors]);

  const handleSubindustrySelect = arrayOfSubindustries => {
    const newSearchArray = [];
    arrayOfSubindustries.map(ele => {
      const findParent = selectedIndustriesInfo.find(
        ind => ind.name === ele.industryLabel,
      );
      if (findParent.subsectors.length >= arrayOfSubindustries.length) {
        findParent.subsectors = [];
      }
      const isSubsectorInside = findParent.subsectors.find(
        sub => sub.name === ele.label,
      );
      if (!isSubsectorInside) {
        const newObj = {
          name: findParent.name,
          slug: findParent.slug,
          subsectors: findParent.subsectors.push({
            name: ele.label,
            slug: ele.value,
          }),
        };
        newSearchArray.push(newObj);
      }
    });
    setSelectedSubindustries(arrayOfSubindustries);
  };

  useEffect(() => {
    let newSubsectors = [];
    if (selectedIndustryHistory?.length < selectedIndustries?.length) {
      subsectors.map(ss => {
        newSubsectors.push({
          label: ss.name,
          value: ss.slug,
          industry: selectedIndustries[selectedIndustries.length - 1],
          industryLabel:
            selectedIndustriesInfo[selectedIndustriesInfo.length - 1].name,
        });
      });

      if (activeSubsectors.length === 0) {
        return setActiveSubsectors(newSubsectors);
      } else {
        return setActiveSubsectors([...newSubsectors, ...activeSubsectors]);
      }
    } else {
      const removedIndustry = selectedIndustryHistory?.filter(
        element => !selectedIndustries.includes(element),
      )[0];
      return setActiveSubsectors(
        activeSubsectors.filter(ele => ele.industry !== removedIndustry),
      );
    }
  }, [subsectors]);

  useEffect(() => {
    dispatch(
      getCountriesRequest({
        regions: selectedRegions || [],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (activeScope === 1) {
      textAreaRef.current?.focus();
    }
    // dispatch(getCountriesRequest({ regions: ["Africa"] }));
  }, [activeScope, regionsChanged]);

  useEffect(() => {
    if (searchLocation) {
      if (typeof searchLocation === "string") {
        setSelectedRegions([JSON.parse(searchLocation).region]);
        setSelectedCountries([JSON.parse(searchLocation).countries]);
      } else {
        setSelectedRegions([searchLocation.regions]);
        setSelectedCountries([searchLocation.countries]);
      }
    }
  }, [searchLocation]);

  useEffect(() => {
    if (
      filteredRegionMenuItems &&
      filteredRegionMenuItems.length === 0 &&
      regionsInputValue.length !== 0
    ) {
      setErrorMsgs({ region: "No regions found by that name" });
    } else if (
      filteredCountryMenuItems &&
      filteredCountryMenuItems.length === 0 &&
      countriesInputValue.length !== 0
    ) {
      setErrorMsgs({ country: "No countries found by that name" });
    } else {
      setErrorMsgs(null);
    }
  }, [filteredRegionMenuItems, filteredCountryMenuItems]);
  useEffect(() => {
    if (selectedRegions && selectedRegions.length) {
      dispatch(
        getCountriesRequest({
          regions: selectedRegions,
        }),
      );
      // clear selected countries on region deselect
      regionsChanged &&
        setSelectedCountries(
          selectedCountries.filter(item =>
            countryMenuItems.map(x => x.name).includes(item),
          ),
        );
    } else {
      setSelectedCountries([]);
      setFilteredCountryMenuItems(null);
      setCountriesInputValue("");
      setErrorMsgs(null);
    }
  }, [selectedRegions]);

  useEffect(() => {
    // clear selected countries on region deselect
    regionsChanged &&
      setSelectedCountries(
        selectedCountries.filter(item =>
          countryMenuItems.map(x => x.name).includes(item),
        ),
      );
  }, [countryMenuItems]);

  useEffect(() => {
    countriesChanged &&
      JSON.stringify(prevSelectedCountries) !==
        JSON.stringify(selectedCountries) &&
      dispatch(searchActions.updateCountries(selectedCountries));
  }, [selectedCountries, prevSelectedCountries]);

  // useEffect(() => {
  //     if (currentSearch) {
  //         dispatch(searchActions.setIndustryDesc(currentSearch.description));
  //         currentSearch.location
  //             ? dispatch(
  //                   searchActions.updateLocation(
  //                       JSON.parse(currentSearch.location),
  //                   ),
  //               )
  //             : dispatch(searchActions.clearLocation());
  //         currentSearch.sectors
  //             ? dispatch(searchActions.addSectors(currentSearch.sectors))
  //             : dispatch(searchActions.removeSearchSectors());
  //     }
  // }, [currentSearch]);

  useEffect(() => {
    dispatch(fetchSectorsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCountries && selectedCountries) {
      dispatch(searchActions.setEditQueryClean());
    } else {
      dispatch(searchActions.setEditQueryDirty());
    }
  }, [selectedRegions, selectedCountries]);

  const loadScope = () => {
    handleSubmitSearch();
  };

  const handleRemoveSearch = () => {
    if (selectedScope) {
      deleteSearch.mutate(selectedScope.id);
    }
  };

  const handleTestPromptBtnClick = async () => {
    await refetch();
  };

  return (
    <div className={classnames([classes.mainWrapper])}>
      {isModalOpen && (
        <Modal
          childData={childData}
          modalStatus={modalStatus}
          getChip={getChip}
        />
      )}

      <div className={classnames([classes.titleWrapper])}>
        <div className={classnames([classes.image])}>
          <NewPumaLogo className={classes.newPumaLogo} />
        </div>
        <div className={classnames([classes.titleTextWrapper])}>
          <Typography variant="h6" className={classnames([classes.title])}>
            PUMA <strong>Scope</strong>
          </Typography>
        </div>
        <Button
          onClick={() => (window.location.href = "/search-type")}
          className={classes.scopeBackToMainBtn}>
          &lt; Back to main selection
        </Button>
        {/* <Tooltip
                    title={
                        <Typography
                            color="white"
                            variant="body2"
                            className={classes.infoTooltipText}>
                            Some text to be written...
                        </Typography>
                    }
                    arrow>
                    <QuestionMarkTooltip
                        className={classnames([classes.scopeSelectTooltip])}
                    />
                </Tooltip> */}
      </div>
      {/*  */}
      <div className={classnames([classes.contentWrapper])}>
        <div className={classnames([classes.searchWrapper])}>
          <div
            style={{ alignItems: "center" }}
            className={classes.scoopeGuidanceSpaceBetweenContainer}>
            <Typography
              className={classnames([classes.scopeGuidanceStepTitle])}>
              By prompt
            </Typography>
            <Button
              className={classes.exampleButton}
              onClick={() => setIsModalOpen(true)}>
              Prompt Examples
            </Button>
          </div>
          <Typography className={classnames([classes.scopeGuidanceHelpText])}>
            Please construct a Scope Prompt that describes your objectives and
            contextual details concerning your need to dig into the Valuer
            database for data insights to support your Deep Dive with PUMA. Aim
            high, and possibly use even 150 words to complete your Scope Prompt.
            Feel free to consider prompt contents to follow a structure, which
            integrates considerations concerning 1) your professional context,
            2) the technological innovations related to your objectives, 3) the
            challenges currently faced in the market, 4) the user needs, and 5)
            examples of what could be considered optimal practical applications
            of the solution you are aiming for.
            <br></br>
            <br></br>
            <i>
              Start by just putting down your first thoughts, and then go to the
              “Test Prompt” feature below the text window, for concrete
              suggestions on how to improve your prompt.
            </i>
          </Typography>
          <Typography className={classnames([classes.scopeGuidanceSubTitle])}>
            Create your own prompt
          </Typography>
          <TextareaAutosize
            ref={textAreaRef}
            minRows={3}
            className={classes.scopeTextarea}
            placeholder="Add description"
            value={scopeValue}
            onChange={e => {
              setScopeValue(e.target.value);
            }}
          />
        </div>
        {/* Filter section */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ paddingBottom: "0px" }}
            className={classnames([classes.filterTitleWrapper])}>
            <div
              className={classnames([classes.filterTitlesBox])}
              onClick={() => setFilterMenuOpen(!filterMenuOpen)}>
              <Plus
                className={classnames([
                  filterMenuOpen ||
                  (selectedRegions[0] && selectedRegions[0].length) ||
                  selectedIndustriesValue.length
                    ? classes.activePlus
                    : classes.plus,
                ])}
              />
              <div className={classnames([classes.fitlerTitle])}>
                Add Scope Filters
              </div>
            </div>
            {/* <div>
                            <Tooltip
                                title={
                                    <Typography
                                        color="white"
                                        variant="body2"
                                        className={classes.infoTooltipText}>
                                        Please utilize the filtering option, if
                                        you want PUMA to exclusively scope
                                        within certain parameters of regions,
                                        countries or industries. Filtering as
                                        part of your scoping will inform PUMA
                                        and often increases your hit rate
                                        noticeably. <br />
                                        <br />
                                        Both the region and industry filters
                                        have two stages. Choosing a region
                                        allows you to specify any countries
                                        within the region. The same goes for
                                        industries into sub-industries.
                                    </Typography>
                                }
                                arrow>
                                <QuestionMarkTooltip
                                    className={classnames([classes.tooltip])}
                                />
                            </Tooltip>
                        </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Button
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                  marginRight: "10px",
                }}
                onClick={handleTestPromptBtnClick}
                className={
                  scopeValue
                    ? classes.scopeGuidanceTestBtn
                    : classes.scopeGuidanceDisabledBtn
                }
                disabled={!scopeValue}>
                {isFetching ? (
                  <>
                    <CircularProgress size={20} />
                    <div style={{ marginLeft: "10px" }}>Prompt testing</div>
                  </>
                ) : (
                  "Test Prompt"
                )}
              </Button>
              <Button
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                }}
                onClick={handleSubmitSearch}
                disabled={scopeValue === ""}
                className={
                  scopeValue === ""
                    ? classes.scopeGuidanceDisabledBtn
                    : classes.scopeGuidanceDeepDiveBtn
                }>
                Go to Deep Dive
              </Button>
            </div>
          </div>
          <div
            style={{
              height: filterMenuOpen ? "auto" : "0px",
              opacity: filterMenuOpen ? 1 : 0,
              paddingBottom: filterMenuOpen ? 30 : 0,
            }}
            className={classnames([classes.inputsWrapper])}>
            <div className={classnames([classes.inputsCountryWrapper])}>
              <div
                onKeyUp={() => handleMenuSearch("region")}
                style={{
                  width: "100%",
                  maxWidth: "330px",
                }}>
                <MultiSelectSort
                  options={filteredRegionMenuItems || regionMenuItems}
                  placeholder="Region"
                  onChange={e => {
                    handleRegionSelect(e);
                  }}
                />
              </div>
              <div
                onKeyUp={() => handleMenuSearch("country")}
                style={{
                  width: "100%",
                  maxWidth: "330px",
                }}>
                <MultiSelectSort
                  options={filteredCountryMenuItems || countryMenuItems}
                  placeholder={
                    selectedRegions[0] && selectedRegions[0].length === 0
                      ? "Select region first"
                      : "Country"
                  }
                  onChange={e => {
                    handleCountrySelect(e);
                  }}
                  isDisabled={
                    selectedRegions[0] && selectedRegions[0].length === 0
                  }
                />
              </div>
            </div>
            <div className={classnames([classes.inputsIndustryWrapper])}>
              <div
                style={{
                  width: "100%",
                  maxWidth: "330px",
                }}>
                <MultiSelectSort
                  options={filteredIndustriesMenuItems || industriesMenuItems}
                  placeholder="Industries"
                  onChange={e => {
                    handleIndustriesSelect(e);
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  maxWidth: "330px",
                }}>
                <MultiSelectSort
                  options={activeSubsectors}
                  placeholder="Sub-industries"
                  onChange={e => {
                    handleSubindustrySelect(e);
                  }}
                  isDisabled={!selectedIndustries[0]}
                />
              </div>
            </div>
          </div>
        </div>
        {!isLoading && data && (
          <div>
            <div className={classnames([classes.horizonalDivider])} />
            <Typography className={classnames([classes.scopeGuidanceHelpText])}>
              <div>
                <Markdown markdown_text={data} />
              </div>
            </Typography>
            <div className={classnames([classes.horizonalDivider])} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OwnPrompt;
