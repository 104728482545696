import { ML_DD_STREAM_URL } from "../constants/endpoints";

export class SocketService {
  constructor() {
    if (!SocketService.instance) {
      this.socket = null;
      SocketService.instance = this;
    }

    return SocketService.instance;
  }

  getSocketConnection() {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.socket = new WebSocket(ML_DD_STREAM_URL);

      this.socket.onopen = () => {
        console.log("WebSocket connection established.");
      };

      this.socket.onclose = ev => {
        console.log("WebSocket connection closed.", ev);
      };

      this.socket.onerror = error => {
        console.error("WebSocket error:", error);
      };
    }
  }

  closeConnection() {
    if (this.socket) {
      this.socket.close();
    }
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  }

  addMessageListener(callback) {
    if (this.socket) {
      this.socket.onmessage = event => {
        const message = JSON.parse(event.data);
        if (message.type === "ping") {
          this.sendMessage({ type: "pong" });
        } else {
          callback(message);
        }
      };
    }
  }
}
